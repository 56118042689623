<template>
  <div class="anonymous-account" v-if="!hide" :class="{'anonymous-account-open': open, 'anonymous-account-close': !open}">
    <div class="anonymous-account-background"></div>
    <div class="anonymous-account-content">

      <div class="content__text">
        <span>Enregistrer ces informations pour votre prochaine commande ?</span>
      </div>
      <div class="content__buttons">
        <button class="action-button action-button-primary" @click="createAccount">Avec plaisir !</button>
        <button class="action-button action-button-close" @click="close">Non merci</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: true,
      hide: false
    }
  },
  methods:{
    createAccount(){
      this.$router.push({name: 'ano_account', query: {redirect: this.$route.fullPath}});
    },
    close(){
      this.open = false;
      localStorage.setItem('anonymous_account_conversion_denied', true);
      setTimeout(() => {
        this.hide = true;
      }, 300);
    }
  }
}
</script>


<style lang="scss">
.anonymous-account
{
  .anonymous-account-background
  {
    background-color: rgba(#000,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  .anonymous-account-content{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 110;
    background-color: #fff;
    padding: 30px 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .content__text
    {
      padding: 0 10px;
      span
      {
        font-weight: 700;
        font-size: 18px;
      }
    }
    .content__buttons
    {
      display: flex;
      gap: 15px;
      margin-top: 28px;
      .action-button
      {
        flex:1;
        border: 0;
        padding: 10px;
        border-radius: 7px;
        font-size: 16px;
      }
      .action-button-primary
      {
        background-color: #00ADEB;
        font-weight: 600;
        color: #fff;
      }
      .action-button-close
      {
        background-color: #EFEFEF;
        color: #c14747;
      }
    }
  }
}

.anonymous-account-open
{
  .anonymous-account-background
  {
    animation: openBackgroundAnonymous 0.3s ease-out;
  }
  .anonymous-account-content
  {
    animation: openSheetAnonymous 0.3s ease-out;
  }
}
.anonymous-account-close
{
  .anonymous-account-background
  {
    animation: closeBackgroundAnonymous 0.3s ease-out;
    opacity: 0;
  }
  .anonymous-account-content
  {
    animation: closeSheetAnonymous 0.3s ease-out;
    transform: translateY(100%);
  }
}

@keyframes openSheetAnonymous {
  from
  {
    transform: translateY(100%);
  }
  to
  {
    transform: translateY(0%);
  }
}

@keyframes closeSheetAnonymous {
  from
  {
    transform: translateY(0%);
  }
  to
  {
    transform: translateY(100%);
  }
}
@keyframes openBackgroundAnonymous {
  from
  {
    opacity: 0;
  }
  to
  {
    opacity: 1;
  }
}

@keyframes closeBackgroundAnonymous {
  from
  {
    opacity: 1;
  }
  to
  {
    opacity: 0;
  }
}


</style>

