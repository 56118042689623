<template>
  <app-page>
    <ui-alert type="error" v-if="error">{{$t('error')}}</ui-alert>


    <div v-if="!isLoading && !error" class="checkout">
      <app-checkout-nav></app-checkout-nav>
      <app-checkout-screen></app-checkout-screen>
      <app-checkout-footer></app-checkout-footer>
    </div>
    <app-checkout-information v-if="!isLoading && !error"></app-checkout-information>
    <app-checkout-receipt v-if="!isLoading && !error"></app-checkout-receipt>

    <app-checkout-anonymous-account v-if="!isLoading && !error && (account && account.anonymous) && showAnonymous"></app-checkout-anonymous-account>
  </app-page>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import '../scss/app_mobile.scss';

import Nav from "@/mobileComponents/Checkout/Nav";
import Screen from "@/mobileComponents/Checkout/Screen";
import Footer from "@/mobileComponents/Checkout/Footer";
import Receipt from "@/mobileComponents/Checkout/Receipt";
import Informations from "@/mobileComponents/Checkout/Informations";
import AnonymousAccount from "@/mobileComponents/Checkout/AnonymousAccount";
import {initCrisp} from "@/crisp";

export default {
  components:{
    'app-checkout-nav': Nav,
    'app-checkout-screen': Screen,
    'app-checkout-footer': Footer,
    'app-checkout-receipt': Receipt,
    'app-checkout-information': Informations,
    'app-checkout-anonymous-account': AnonymousAccount
  },
  data(){
    return {
      showAnonymous: false
    }
  },
  metaInfo(){
    return {
      title: this.isLoading ? '' : this.checkout.merchant.name,
      titleTemplate: '%s | Suivi de votre commande Delicity'
    }
  },
  methods: {
    ...mapActions(['loadCheckout', 'getAccountAction']),
    load(){
      this.loadCheckout({
        merchantSlug: this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host,
        orderToken: this.$route.params.orderToken,
        shareToken: this.$route.params.shareToken
      }).then(() => {
        if(this.checkout.currentUserLogged){
          initCrisp(
              this.checkout.receipt.client.email,
              this.checkout.receipt.client.name,
              this.checkout.receipt.client.id,
              this.checkout.orderId
          );
        }

        this.shareInUrl();
      });
    },
    shareInUrl(){
      if(!this.$route.params.shareToken){
        this.$router.push({to: 'checkout', params: {
            merchantSlug:this.$route.params.merchantSlug,
            orderToken:this.$route.params.orderToken,
            shareToken:this.shareToken
          }});
      }
    }
  },
  computed: {
    ...mapGetters(['checkout', 'error', 'shareToken', 'account']),
    isLoading(){
      return !(this.checkout !== null);
    },
  },
  mounted() {
    localStorage.setItem('last_page', 'checkout');
    if(!this.checkout){
      this.load();
    }
    else{
      this.shareInUrl();
    }
    this.getAccountAction();

    if(!localStorage.getItem('anonymous_account_conversion_denied')){
      setTimeout(() => {
        this.showAnonymous = true;
      }, 4000);
    }

    if(!localStorage.getItem('bearer')){
      setInterval(() => {
        this.load();
      }, 10000);
    }
    else{
      this.$nextTick(() => {
        Echo.private('order.' + this.$route.params.orderToken)
            .listen('CheckoutUpdate', (e) => {
              this.load();
            });
      });
      setInterval(() => {
        this.load();
      }, 10000);
    }
  },
}
</script>
