<template>
  <app-panel type="panel"
             class="panel-checkout-informations"
             event-name="openInformations"
             :title="$t('more_informations')"
             hide-validate-button>

    <div class="panel-body" v-if="checkout">


      <div class="checkout-informations__address">
        <div class="title"><i class="material-icons notranslate">store_mall_directory</i> <span>Votre restaurant</span></div>

        <div class="address" v-if="checkout.merchant">
          <strong>{{checkout.merchant.name}}</strong>
          <span>{{checkout.merchant.address.address}}</span>
          <span v-if="checkout.merchant.address.address2">{{checkout.merchant.address.address2}}</span>
          <span>{{checkout.merchant.address.zip}} {{checkout.merchant.address.city}}</span>
          <span>{{ checkout.merchant.address.comment}}</span>

          <a :href="'tel:'+checkout.merchant.phone" class="phone"><i class="material-icons notranslate">phone</i>{{checkout.merchant.phone}}</a>
        </div>

      </div>

      <div class="checkout-informations__address" v-if="checkout.clientAddress && checkout.orderType === 'delivery'">
        <div class="title"><i class="material-icons notranslate">place</i> <span>Adresse de livraison</span></div>
        <div class="address" >
          <strong >{{checkout.clientAddress.name}}</strong>
          <span>{{checkout.clientAddress.address}}</span>
          <span v-if="checkout.clientAddress.address2">{{checkout.clientAddress.address2}}</span>
          <span>{{checkout.clientAddress.zip}} {{checkout.clientAddress.city}}</span>
          <span>{{ checkout.clientAddress.comment}}</span>
          <small>{{checkout.clientAddress.houseInfo}}</small>
          <small>{{checkout.clientAddress.houseName}}</small>
        </div>
      </div>

      <div class="checkout-informations__courier" v-if="checkout.courier && checkout.orderType === 'delivery'">
        <img :src="checkout.courier.avatar.path">
        <div class="courier-info">
          <span>{{ $t('order_delivered_by') }}</span>
          <strong>{{checkout.courier.name}}</strong>
        </div>
      </div>

      <div class="checkout-informations__share" v-if="shareBtn">
          <button class="btn btn-light" @click="share">
            <i class="material-icons notranslate">share</i>
            <span>{{$t('share_tracking')}}</span>
          </button>
      </div>


    </div>
  </app-panel>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data(){
    return {
      shareBtn: false
    }
  },
  computed: {
    ...mapGetters(['checkout', 'shareToken'])
  },
  methods: {
    ...mapActions(['getShareToken']),
    share(){

      if (navigator.share) {
        navigator.share({
          text: 'Voici ma commande Delicity chez ' + this.checkout.merchant.name,
          url: location.href
        }).then(() => {
        }).catch(console.error);
      }

    }
  },
  mounted() {
    if (navigator.share) {
      this.shareBtn = true;
    }
  }
}
</script>
