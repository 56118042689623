<template>
  <div class="checkout-nav" v-show="showNav">
    <div class="back-action">
      <i class="material-icons notranslate">keyboard_backspace</i>
    </div>

    <template v-if="checkout.currentStatus.status !== 'taken_preorder'">
      <div class="time">
        <div class="estimated" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived">
          <transition name="slide-fade-vertical" v-if="checkout.eta && !dontShowTime">
            <strong :key="checkout.eta">{{ checkout.eta }}</strong>
          </transition>
          <transition name="slide-fade-vertical" v-if="checkout.etp && !checkout.eta && !dontShowTime">
            <strong :key="checkout.etp">{{ checkout.etp }}</strong>
          </transition>
          <div v-if="dontShowTime">
            <strong key="ended">{{$t('ready')}}</strong>
          </div>
        </div>
        <div class="estimated" v-if="checkout.courier && checkout.courier.arrived">
          <strong>{{ $t('courier_is_arrived') }}</strong>
        </div>
        <button class="btn btn-light" v-if="shareBtn" @click="share">{{$t('share')}}</button>
      </div>
      <div class="status" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived">
        <strong v-if="$te('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType)">{{$t('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType, {merchantName: checkout.merchant.name, courierName: checkout.courier ? checkout.courier.name : ''})}}</strong>
        <strong>Commande {{checkout.orderRef}}</strong>
        <span v-if="checkout.maxArrival && checkout.orderType === 'delivery'">{{ $t('checkout_max_eta', {time: checkout.maxArrival}) }}</span>
      </div>
      <div class="status" v-if="checkout.courier && checkout.courier.arrived">
        <strong>{{$t('courier_is_arrived_text', {courierName: checkout.courier ? checkout.courier.name : $t('courier')})}}</strong>
      </div>
    </template>
    <template v-else>
      <div class="time-preorder">
        <div class="estimated">
          <template v-if="checkout.preorder.today">
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_today_arrival_delivery', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_today_arrival_second_delivery', {hour:checkout.preorder.dateHour, max:checkout.preorder.dateHourMax})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_today_arrival_pickup', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_today_arrival_second_pickup', {hour:checkout.preorder.dateHour})}}</strong>
          </template>
          <template v-else>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_arrival_delivery', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_arrival_second_delivery', {hour:checkout.preorder.dateHour, max:checkout.preorder.dateHourMax})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_arrival_pickup', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_arrival_second_pickup', {hour:checkout.preorder.dateHour})}}</strong>
          </template>
        </div>
        <div class="status" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived">
          <strong v-if="$te('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType)">{{$t('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType, {merchantName: checkout.merchant.name, courierName: checkout.courier ? checkout.courier.name : ''})}}</strong>
          <span v-if="checkout.maxArrival && checkout.orderType === 'delivery'">{{ $t('checkout_max_eta', {time: checkout.maxArrival}) }}</span>
        </div>
      </div>
    </template>

    <div class="bar-container"><div class="bar" :style="{width: barWidth + '%'}"></div></div>

    <div class="alert alert-info border-0 mb-0 rounded-0" v-if="checkout.orderType === 'on_site_counter' && checkout.currentStatus.id < 30">{{ $t('on_site_counter_checkout_message') }}</div>

    <div class="alert alert-rush border-0 m-3 mb-0" v-if="checkout.merchantLate">
      <h4>{{$t('checkout_nav_rush_title')}}</h4>
      <p>{{$t('checkout_nav_rush_description')}} <a :href="'tel:'+checkout.merchant.phone" v-if="checkout.merchant.phone" class="phone">{{$t('checkout_nav_rush_cta')}}</a></p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data(){
    return {
      barWidth: 50,
      shareBtn: false
    }
  },
  computed: {
    ...mapGetters(['checkout']),
    showNav(){
      return this.checkout.currentStatus.status !== 'delivered'
    },
    dontShowTime(){
      return this.checkout.orderType === 'on_site_counter' && this.checkout.currentStatus.id > 20;
    }
  },
  methods: {
    ...mapActions(['getShareToken']),
    getBarWidth(){
      let totalStatus = this.checkout.statues.length;
      let current = this.checkout.statues.findIndex(s => s.id === this.checkout.currentStatus.id) + 1;
      this.barWidth = current * 100 / totalStatus;
    },
    share(){
      if (navigator.share) {
        navigator.share({
          text: 'Voici ma commande Delicity chez ' + this.checkout.merchant.name,
          url: location.href
        }).then(() => {
        }).catch(console.error);
      }
    }
  },
  mounted() {
    this.getBarWidth();
    if(navigator.share){
      this.shareBtn = true;
    }
  },
  watch:{
    checkout:{
      deep: true,
      handler(val){
        this.getBarWidth();
      }
    }
  }
}
</script>


